import s from '../index.module.css';

export default function Address() {
  return (
    <div className={s.footer_address}>
      <p> R&F INVESTMENTS LTD </p>
      <p> Harmony Suites 12 </p>
      <p> 8240 Nesebar, Bulgarien </p>
    </div>
  );
}

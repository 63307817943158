import { useState } from 'react';
import Consultation from '../Consultation';
import CryptoBlock from '../CryptoBlock';
import CustomerReviews from '../CustomerReviews';
import Footer from '../Footer';
import Header from '../Header';
import Modal from '../Modal';
import Nav from '../Nav';
import Range from '../Range';
import Services from '../Services';
import Touren from '../Touren';
import YouTubeLink from '../YouTubeLink';

function App() {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  document.body.style.overflow = modal ? 'hidden' : 'auto';

  return (
    <div>
      <Header />
      <Nav />
      <Services />
      <YouTubeLink />
      <Touren setModal={setModal} setModalContent={setModalContent} />
      <Modal modal={modal} setModal={setModal} modalContent={modalContent} />
      <Range />
      <CryptoBlock />
      <CustomerReviews />
      <Consultation />
      <Footer setModal={setModal} setModalContent={setModalContent} />
    </div>
  );
}

export default App;

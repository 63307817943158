import React from 'react';
import s from './index.module.css';

export default function InfoText() {
	return (
		<div className={s.info_text}>
			<h2>RF Investments</h2>
			<div>
				<p>
					RF Investments ist ein europaweit tätiger, auf die
					Wohnimmobilienwirtschaft spezialisiertes Unternehmen. Der
					Tätigkeitsschwerpunkt liegt im Verkauf und Vermietung von
					Immobilienobjekten auf gefragten Urlaubsorten. Mit ihrem Angebot
					realisiert die RF Investments Potentiale, sowohl für private
					Kapitalanleger, als auch für Selbstnutzer. Unsere kompetenten
					Mitarbeiter organisieren den Kauf und begleiten Sie bei den ganzen
					Prozessen bis zur Schlüsselübergabe von ihrer Traumimmobilie.
				</p>
			</div>
		</div>
	);
}

export const offices = [
    {
      id: 1,
      image: '../../media/offices/office_1_a.jpg',
      title: 'Alexander Judin',
      contact: '+497313756204',
      location: [48.326, 10.051],
      city: 'Senden',
    },
    {
      id: 2,
      image: '../../media/offices/office_2_a.jpg',
      title: 'Anton Fuchs, CEO RF Invest',
      contact: '+4917610175414',
      location: [47.874, 10.534],
      city: 'Kaufbeuren',
    },
    {
      id: 3,
      image: '../../media/offices/office_3_a.jpg',
      title: 'Artur Runge, CEO RF Invest',
      contact: '+4917624672123',
      location: [48.370, 10.902],
      city: 'Augsburg',
    },
    {
      id: 4,
      image: '../../media/offices/office_4_a.jpg',
      title: 'Dmitri Seher',
      contact: '+491721018359',
      location: [48.158, 11.568],
      city: 'München',
    },
    {
      id: 5,
      image: '../../media/offices/office_5_a.jpg',
      title: 'Katarina Sturova',
      contact: '+4915733214430',
      location: [48.403, 9.994],
      city: 'Ulm',
    },
    {
      id: 6,
      image: '../../media/offices/office_6_a.jpg',
      title: 'Oleg Zirkov',
      contact: '+4917662985309',
      location: [47.923, 10.668],
      city: 'Kaufbeuren',
    },
    {
      id: 7,
      image: '../../media/offices/office_7_a.jpg',
      title: 'Oxana Zweifel',
      contact: '+491715847020',
      location: [48.629, 12.495],
      city: 'Dingolfing',
    },
    {
      id: 8,
      image: '/media/offices/office_8_a.jpg',
      title: 'Viktor Maas',
      contact: '+4917643499951',
      location: [50.941, 6.956],
      city: 'Köln',
    }
]

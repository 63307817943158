import React from 'react';
import s from './index.module.css';

export default function Impressum() {
	return (
		<div className={s.impressum}>
			<h1>Impressum</h1>

			<h2>Angaben gemäß § 5 TMG</h2>
			<div>
				<p>R&F INVESTMENTS LTD</p>
				<p>Harmony Suites 12</p>
				<p>8230 Nesebar, Bulgarien</p>
			</div>
			<div>
				<p>Handelsregister: ЕИК/ПИК 206566046</p>
				<p>Registergericht: Bulgarian business register</p>
			</div>
			<h3>Vertreten durch: </h3>
			<p>Anton Fuchs, Artur Runge</p>

			<h2>Kontakt</h2>
			<div>
				<p>Telefon: +49 176 10175414, +49 176 24672123</p>
				<p>E-Mail: info@rf-invest.eu</p>
			</div>

			<h2>Umsatzsteuer-ID</h2>
			<div>
				<p>
					Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
					<p>BG206566046</p>
				</p>
			</div>

			<h2>EU-Streitschlichtung</h2>
			<div>
				<p>
					Die Europäische Kommission stellt eine Plattform zur
					Online-Streitbeilegung (OS) bereit:
				</p>
				<a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">
					https://ec.europa.eu/consumers/odr
				</a>
				.<p>Unsere E-Mail-Adresse finden Sie oben im Impressum</p>
			</div>

			<h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
			<div>
				<p>
					Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
					vor einer Verbraucherschlichtungsstelle teilzunehmen.
				</p>
			</div>

			<h2>Haftung für Inhalte</h2>
			<div>
				<p>
					Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
					auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
					§§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
					verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
					überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
					Tätigkeit hinweisen.
				</p>
			</div>
			<div>
				<p>
					Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
					Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
					Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
					Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
					von entsprechenden Rechtsverletzungen werden wir diese Inhalte
					umgehend entfernen.
				</p>
			</div>

			<h2>Haftung für Links</h2>
			<div>
				<p>
					Unser Angebot enthält Links zu externen Websites Dritter, auf deren
					Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
					fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
					verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
					Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
					Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
					Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
				</p>
			</div>
			<div>
				<p>
					Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
					ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
					Bekanntwerden von Rechtsverletzungen werden wir derartige Links
					umgehend entfernen.
				</p>
			</div>

			<h2>Urheberrecht</h2>
			<div>
				<p>
					Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
					Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
					Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
					Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
					jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
					sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
				</p>
			</div>
			<div>
				<p>
					Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
					wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
					Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
					eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
					entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
					werden wir derartige Inhalte umgehend entfernen.
				</p>
			</div>
		</div>
	);
}
